<template>
  <div class="px-5 md:px-24">
    <div class="grid grid-flow-row md:px-60 gap-5">
      <div class="">
        <label for="name" class="block mb-2 text-sm font-semibold StemTextSecond"
          >Name *</label
        >
        <input
          type="text"
          name="name"
          placeholder="Name"
          v-model="name"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border-2 border-StemYellow
            focus:outline-none
            focus:ring
            focus:ring-StemYellow
            focus:border-StemYellow
          "
        />
      </div>
      <div class="">
        <label for="email" class="block mb-2 text-sm font-semibold StemTextSecond"
          >Email Address *</label
        >
        <input
          type="email"
          name="email"
          placeholder="you@email.com"
          v-model="email"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border-2 border-StemYellow
            focus:outline-none
            focus:ring
            focus:ring-StemYellow
            focus:border-StemYellow
          "
        />
      </div>
      <div class="">
        <label for="address" class="block mb-2 text-sm font-semibold StemTextSecond"
          >Adresa *</label
        >
        <input
          type="text"
          name="address"
          placeholder="Rr. Ilinden p.n., Tetove"
          v-model="address"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border-2 border-StemYellow
            focus:outline-none
            focus:ring
            focus:ring-StemYellow
            focus:border-StemYellow
          "
        />
      </div>
      <div class="">
        <label for="postalcode" class="block mb-2 text-sm font-semibold StemTextSecond"
          >Postal code *</label
        >
        <input
          type="text"
          name="postalcode"
          placeholder="1200"
          v-model="postalcode"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border-2 border-StemYellow
            focus:outline-none
            focus:ring
            focus:ring-StemYellow
            focus:border-StemYellow
          "
        />
      </div>
      <div class="">
        <label for="phone" class="block mb-2 text-sm font-semibold StemTextSecond"
          >Numer kontakti *</label
        >
        <input
          type="tel"
          pattern="[0-9]{3}-[0-9]{3}-[0-9]{3}"
          name="phone"
          placeholder="070123123"
          v-model="phone"
          required
          class="
            w-full
            px-3
            py-2
            placeholder-gray-300
            border-2 border-StemYellow
            focus:outline-none
            focus:ring
            focus:ring-StemYellow
            focus:border-StemYellow
          "
        />
      </div>
      <div class="">
        <input type="checkbox" id="checkbox" v-model="checked" required />
        <label for="checkbox"
          >Check the box if you agree with our privacy policy</label
        >
      </div>
      <div class="">
        <button
          class="
            rounded-lg
            bg-StemYellow
            hover:bg-transparent hover:text-StemYellow
            text-white
            border
            hover:border-StemYellow
            text-xl
            font-normal
            shadow-2xl
            px-10
            py-4
          "
        >
          Order
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>