<template>
  <div class="pt-32">
    <div class="grid grid-flow-row md:grid-flow-col">
      <!-- item list -->
      
      <!-- catrt -->

      <div class="px-4 md:px-24">
        <p v-if="cartItems.length <= 0">No Cart items</p>
        <div
          class="grid grid-flow-col border-b-2 border-black"
          v-for="cart in cartItems"
          :key="cart"
          :data="cart"
        >
          <div class="h-40 w-40">
            <div class="grid place-self-center">
              <img :src="cart.image" alt="" />
            </div>
          </div>
          <div class="font-bold text-blue-800 place-self-center">
            {{ cart.title }}
          </div>
          <div
            class="text-StemYellow place-self-end border-2 border-StemYellow"
          >
            {{ cart.price }} DEN
          </div>
          <div class="grid grid-flow-row place-content-center gap-y-3">
            <button v-on:click="addTocart(cart)" class="bg-green-500 text-xl w-10 h-10 rounded-full text-white text-center">+</button>
            <div>{{cart.amount}}</div>
            <button v-if="cart.amount > 0" v-on:click="reduceAmount(cart)" class="bg-StemLightRed text-xl w-10 h-10 rounded-full text-white text-center">-</button>

          </div>
          <div
            class="h-5 w-5 place-self-center cursor-pointer text-StemLightRed"
            v-on:click="deleteCart(cart)"
          >
            <img src="./../../assets/Svg/delsvg.svg" alt="" />
          </div>
        </div>
        <h5 class="text-StemYellow">Total: {{ totalPrice }} DEN</h5>
      </div>
      <!-- cart end -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import products from "@/data/products";
export default {
  data() {
    return {
      items: products.data,
      // cartItems: [],
      quantity: "",
    };
  },
  created() {},

  computed: {
    ...mapGetters(['cartItems']),
    totalPrice() {
      return this.cartItems.reduce((acc, item) => acc += +item.price * +item.amount, 0);
    },
  },

  methods: {
    ...mapActions(["addProductToCart", 'removeProductFromCart', 'reduceAmountOfProducts']),
    addTocart(product) {
      // if (this.cartItems.includes(products)) {
      //   alert("already there");
      // } else {
      //   this.cartItems.push(products);
      // }
      this.addProductToCart(product);
    },

    deleteCart(cart) {
      // this.cartItems.splice(this.cartItems.indexOf(cart), 1);
      this.removeProductFromCart(cart.cartId);
    },

    reduceAmount(cart){
      this.reduceAmountOfProducts(cart.cartId);
    }
  },
};
</script>

<style scoped>
#carts {
  width: 100%;
  min-height: 40px;
  background: #eee;
  padding: 20px;
}

.items {
  padding: 10px;
  margin-top: 50px;
  padding: 20px;
}

.items p {
  margin-top: 2px;
  background: #f5f5f5;
  padding: 4px 10px;
  cursor: pointer;
  position: relative;
}

.cart-btn {
  position: absolute;
  right: 2%;
}

.cart-price {
  margin-left: 20px;
}

.delete {
  margin-left: 20px;
}
</style>