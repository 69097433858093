<template>
  <div class="bg-white">
  <NavBarNew></NavBarNew>
  <CheckOutProductList></CheckOutProductList>
  <CheckOutForm></CheckOutForm>
  <Footer></Footer>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBarNew from "@/components/HomeScreen/NavBarNew.vue";
import CheckOutProductList from "@/components/CheckOutScreen/CheckOutProductList.vue"
import CheckOutForm from "@/components/CheckOutScreen/CheckOutForm.vue"

import Footer from "@/components/HomeScreen/Footer.vue";

export default {
  name: "CheckOut",
  components: {
    NavBarNew,
    CheckOutProductList,
    CheckOutForm,
    Footer,
  },
};
</script>